import { Controller } from "@hotwired/stimulus"
import "jQuery-QueryBuilder"
import jquery from 'jquery'

window.$ = jquery

// Connects to data-controller="query-builder"
export default class extends Controller {
  static targets = ["submitButton", "builderBox", "inputField", "clearButton"]

  connect() {
    this.buildQueryBuilder()
  }

  buildQueryBuilder() {
    var context = this;
    $(this.builderBoxTarget).queryBuilder({
      filters: [{
        id: 'body + title',
        label: 'Name',
        type: 'string',
        operators: ['contains', 'not_contains']
      }],

      default_filter: 'body + title',
      display_empty_filter: false
    });

    var currentRules = $(context.inputFieldTarget).val()
    if (!$.isEmptyObject(currentRules)) {
      $(context.builderBoxTarget).queryBuilder('setRules', JSON.parse(currentRules))
    }

    $(this.submitButtonTarget).on('click', function() {
      var result = $(context.builderBoxTarget).queryBuilder('getRules');
      if (!$.isEmptyObject(result)) {
        $(context.inputFieldTarget).val(JSON.stringify(result))
      } else {
        // this prevents the form from submitting
        // becuase one of the rules is invalid
        return false;
      }
    });

    $(this.clearButtonTarget).on('click', function() {
      var result = $(context.builderBoxTarget).queryBuilder('reset');
      $(context.inputFieldTarget).val(JSON.stringify(result))
    });
  }
}
