import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

// Connects to data-controller="remote-modal"
export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element)
    this.modal.show()
    document.addEventListener('turbo:submit-end', this.handleSubmit)
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  hideBeforeRender(event) {
    if (this.isOpen()) {
      this.close()
    }
  }

  isOpen() {
    return this.element.classList.contains("show")
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.close()
    }
  }

  close() {
    event.preventDefault()
    this.element.addEventListener('hidden.bs.modal', event.detail.resume)
    this.modal.hide()
  }
}
