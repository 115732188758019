import { Controller } from "@hotwired/stimulus"
import VirtualizedList from 'virtualized-list';

// Connects to data-controller="virtual-scroll"
export default class extends Controller {
  static targets = [
    "toggler",
    "viewport"
  ]

  async connect() {
    // this whole controller is responsible
    // for drawing JSON clauses. This is
    // how we fetch them async.
    const clausesResponse = await fetch(`/projects/${window.projectID}/documents/${window.documentID}`)
    this.clausesJson = JSON.parse(await clausesResponse.text())

    // Initial magic mode: off
    this.magicOn = false;
    let that = this;
    $(this.togglerTarget).on('click', function() {
      that.magicOn = !that.magicOn;
      $(that.togglerTarget).toggleClass("btn-primary");
      document.dispatchEvent(new Event('redrawVirtualScroll'));
    });

    this.boundRenderFunction = this.renderFunction.bind(this);

    // Clear the dummy loading text on the page
    this.viewportTarget.innerHTML = '';

    let initialIndex = 0
    if (window.location.hash !== '') {
      let clauseId = window.location.hash.slice(8)
      initialIndex = this.filteredClauses().findIndex(c => c.id === clauseId)
      if (initialIndex == -1) { initialIndex = 0 }
    }

    this.virtualizedList = new VirtualizedList(this.viewportTarget, {
      height: this.viewportTarget.getBoundingClientRect().height,
      rowCount: this.filteredClauses().length,
      renderRow: index => this.boundRenderFunction(index),
      rowHeight: index => this.filteredClauses()[index].height_in_px,
      initialIndex: initialIndex
    });

    // setup a callback so that we
    // can trigger a redraw from outside
    // this stimulus controller
    this.redrawFunction = this.redraw.bind(this)
    document.addEventListener("redrawVirtualScroll", this.redrawFunction)
  }

  filteredClauses() {
    if(window.matchesJson.length === 0) {
      return this.clausesJson;
    } else if(this.magicOn === false) {
      return this.clausesJson;
    } else {
      return this.clausesJson.filter(c => window.matchesJson.includes(c.id))
    }
  }

  redraw() {
    this.virtualizedList.setRowCount(this.filteredClauses().length-1);
  }

  scroll(event) {
    let clauseIndex = this.filteredClauses().findIndex(clause => clause.id === event.params.clauseId)
    if (clauseIndex > -1) {
      this.virtualizedList.scrollToIndex(clauseIndex);
    }
  }

  renderFunction(index) {
    const clause = this.filteredClauses()[index];
    const liStyle = `padding-left: ${clause.margin_in_px}px; width:${this.data.get("maxWidthValue")}px`
    let divClass = "indeterminite";
    if(window.includedJson.includes(clause.id)) {
      divClass = "include";
    } else if(window.excludedJson.includes(clause.id)) {
      divClass = "exclude";
    }
    if(window.matchesJson.length !== 0) {
      if(window.matchesJson.includes(clause.id)) {
        divClass += " search-match";
      } else {
        divClass += " not-search-match";
      }
    } else {
      divClass += " not-search-match";
    }
    const htmlString = `
      <div class="text-body clause ${divClass}" id="clause_${clause.id}">
        <div class="clause-icons">
          <form class="button_to include-form" method="post" action="/projects/${window.projectID}/clauses/${clause.id}/fire?verb=include%21">
            <button class="btn px-1" type="submit">
              <svg class="svg-inline--fa fa-circle-plus text-success" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"></path></svg>
            </button>
          </form>
          <form class="button_to include-indetermine-form" method="post" action="/projects/${window.projectID}/clauses/${clause.id}/fire?verb=indeterminate%21">
            <button class="btn px-1" type="submit">
              <svg class="svg-inline--fa fa-circle-plus text-success" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"></path></svg>
            </button>
          </form>
          <form class="button_to exclude-form" method="post" action="/projects/${window.projectID}/clauses/${clause.id}/fire?verb=exclude%21">
            <button class="btn px-1" type="submit">
              <svg class="svg-inline--fa fa-circle-minus text-danger" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-minus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path></svg>
            </button>
          </form>
          <form class="button_to exclude-indetermine-form" method="post" action="/projects/${window.projectID}/clauses/${clause.id}/fire?verb=indeterminate%21">
            <button class="btn px-1" type="submit">
              <svg class="svg-inline--fa fa-circle-minus text-danger" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-minus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path></svg>
            </button>
          </form>
          <a data-turbo-frame="comment_modal" class="btn px-1 opacity-25" href="/comments/new?comment%5Bcommentee_id%5D=${clause.id}&amp;comment%5Bcontext_id%5D=${window.projectID}">
            <svg class="svg-inline--fa fa-comment-dots text-warning" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="comment-dots" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM128 272c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zm160-32c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z"></path></svg>
          </a>
        </div>
        <li style="${liStyle}" value="0">
          <strong>${clause.title}</strong>
          ${clause.body ?? ''}
        </li>
      </div>
    `
    return document.createRange().createContextualFragment(htmlString);
  }
}