function fireClauseEvent(eventName, relevantClauseIds) {
  allClauses = document.querySelectorAll('.clause-row')

  relevantClauseDomIds = relevantClauseIds.map(id => `#clause_${id}`).join()
  relevantClauses = document.querySelectorAll(relevantClauseDomIds)

  switch(eventName) {
    case("searchMatch"):
      for (let elem of allClauses) {
        elem.classList.add('not-search-match');
        elem.classList.remove('search-match');
      }

      for (let elem of relevantClauses) {
        elem.classList.replace('not-search-match', 'search-match');
      }
      break;
    case("include"):
      for (let elem of relevantClauses) {
        elem.classList.add('include');
        elem.classList.remove('exclude');
      }
      break;
    case("exclude"):
      for (let elem of relevantClauses) {
        elem.classList.remove('include');
        elem.classList.add('exclude');
      }
      break;
    case("indetermine"):
      for (let elem of relevantClauses) {
        elem.classList.remove('include');
        elem.classList.remove('exclude');
      }
      break;
    case("reset"):
      for (let elem of allClauses) {
        elem.classList.remove('not-search-match');
        elem.classList.remove('search-match');
      }
      break;
    default:
      console.log("default")

  }
}

window.fireClauseEvent = fireClauseEvent